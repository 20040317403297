/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

function Seo({ seo, thumbnail, pathname, lang, author }) {
  const defaultDesc = seo.metaDesc ?? '';
  const defaultAuthor = author ?? 'Entes';
  const defaultTitle = seo.title ?? '';
  const defaultImage = seo.opengraphImage ? seo.opengraphImage.sourceUrl : (thumbnail) ? thumbnail : '';
  const canonical = `${process.env.GATSBY_SITE_URL}${pathname ?? ''}`;
  const defaultLang = lang ?? 'es_PE';
  return (
    <Helmet
      htmlAttributes={{
        lang: defaultLang,
      }}
      title={defaultTitle}
      titleTemplate={defaultTitle}
      link={
        canonical
          ? [
            {
              rel: 'canonical',
              href: canonical,
            },
          ]
          : []
      }
      meta={[
        {
          name: `description`,
          content: defaultDesc,
        },
        {
          name: `og:locale`,
          content: defaultLang,
        },
        {
          property: `og:title`,
          content: defaultTitle,
        },
        {
          property: `og:description`,
          content: defaultDesc,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: canonical,
        },
        {
          name: `twitter:creator`,
          content: defaultAuthor,
        },
        {
          name: `twitter:title`,
          content: defaultTitle,
        },
        {
          name: `twitter:description`,
          content: defaultDesc,
        },
      ]
        .concat(
          defaultImage
            ? [
              {
                property: 'og:image',
                content: defaultImage,
              },
              {
                name: 'twitter:card',
                content: 'summary_large_image',
              },
            ]
            : [
              {
                name: 'twitter:card',
                content: 'summary',
              },
            ]
        )
      }
    />
  );
}

Seo.defaultProps = {
  lang: `es`,
  meta: [],
  description: ``,
};
/*
Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  // image: PropTypes.shape({
  //   src: PropTypes.string.isRequired,
  //   height: PropTypes.number.isRequired,
  //   width: PropTypes.number.isRequired,
  // }),
  pathname: PropTypes.string,
};
*/
export default Seo;
